import React, { useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";

import Modal from "@components/Modal";
import {
  IJobFunctionItem,
  IModal,
  ISubJobFunctionItem,
  IBusinessUnitItem,
  ICountry,
  IRegion,
} from "@types";
import {
  ErrorServices,
  ICreateJobForEvaluation,
  useCreateJobForEvaluationMutation,
  useFetchAllJobFunctionsQuery,
  useFetchAllBusinessUnitsQuery,
} from "@services";
import { useTypedSelector } from "@/hooks";
import { handleJobDetails, KeyAndDisplayName } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";
import { evaluationModelOptions } from "@/constants";

const { Option } = Select;

export type dropdown = string | undefined;

export interface IAddJob extends IModal {
  isVisible: boolean;
  setIsVisible: () => void;
}

const AddJob: React.FC<IAddJob> = ({ isVisible, setIsVisible }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;
  const { countries } = useTypedSelector(state => state.countries);

  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};

  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );

  const { jobDetails } = useTypedSelector(state => state?.jobEvaluation);

  const disabled =
    !jobDetails.departmentName ||
    !jobDetails.evaluation_model?.key ||
    !jobDetails.jobTitle ||
    !jobDetails.job_function?.id ||
    !jobDetails.noOfDirectReports ||
    !jobDetails.sub_job_function?.id;

  const [createJobForEvaluation, { isLoading }] =
    useCreateJobForEvaluationMutation();

  const onSubmit = async () => {
    try {
      const body: ICreateJobForEvaluation = {
        job_title: jobDetails.jobTitle,
        department_name: jobDetails.departmentName,
        business_unit: jobDetails.business_unit,
        region: jobDetails.region,
        country: jobDetails.country_id,
        evaluation_model: jobDetails.evaluation_model?.key,
        no_direct_report: jobDetails.noOfDirectReports,
        job_function: jobDetails.job_function?.id,
        sub_job_function: jobDetails.sub_job_function?.id,
      };
      const payload = await createJobForEvaluation({
        company_id,
        body,
      }).unwrap();

      payload.data.id &&
        history.push(paths.client.jobEvaluation.create(payload.data.id));

      showSuccessPopup({
        title: "New Job Created",
        desc: `You have successfully created new job.`,
      });
      setIsVisible();
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const {
    departmentName,
    business_unit,
    region,
    country_id,
    evaluation_model,
    noOfDirectReports,
    jobTitle,
    job_function,
    sub_job_function,
  } = jobDetails;

  const handleChange = (event: any) => {
    dispatch(
      handleJobDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  return (
    <Modal
      footer={[
        <Button disabled={disabled} key="1" type="primary" onClick={onSubmit}>
          {`Add Job`}
        </Button>,
        <Button
          key="2"
          onClick={() => {
            setIsVisible();
          }}
        >
          Cancel
        </Button>,
      ]}
      title={`Create a Job For Your Organisation`}
      isVisible={isVisible}
    >
      <>
        <Row className="modal__row">
          <Col span={11}>
            <label>Department Name</label>
            <Input
              placeholder="Enter department name here..."
              size="large"
              name={"departmentName"}
              onChange={handleChange}
              value={departmentName || ""}
            />
          </Col>
        </Row>
        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label>Business Unit</label>
            <Select
              onChange={(business_unit?: number) => {
                handleChange({
                  target: { name: "business_unit", value: business_unit },
                });
              }}
              value={business_unit || undefined}
              showArrow={true}
              size="large"
              placeholder="Select business unit from here..."
            >
              {businessUnits?.map((option: Partial<IBusinessUnitItem>) => (
                <Option key={option?.id} value={option?.id!}>
                  {option?.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={11}>
            <label>Region</label>
            <Select
              disabled={!business_unit}
              onChange={(region: number) => {
                handleChange({
                  target: { name: "region", value: region },
                });
              }}
              value={region || undefined}
              showArrow={true}
              size="large"
              placeholder="Select Region from here..."
            >
              {businessUnits
                ?.filter((elm: any) => elm.id === business_unit)[0]
                ?.regions?.map((option: Partial<IRegion>) => (
                  <Option key={option?.id} value={option?.id!}>
                    {option?.name}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>

        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label>Country</label>
            <Select
              disabled={!region}
              onChange={(country_id: number) => {
                handleChange({
                  target: { name: "country_id", value: country_id },
                });
              }}
              value={country_id || undefined}
              showArrow={true}
              size="large"
              showSearch={true}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select country from here..."
            >
              {businessUnits
                ?.filter((elm: any) => elm.id === business_unit)[0]
                ?.regions?.filter((elm: any) => elm.id === region)[0]
                ?.countries?.map((option: Partial<IRegion>) => (
                  <Option key={option?.id} value={option?.id!}>
                    {option?.name}
                  </Option>
                ))}
            </Select>
          </Col>

          <Col span={11}>
            <label>Evaluation Model</label>
            <Select
              onChange={(serialized_evaluationModel: string) => {
                const evaluationModelData: KeyAndDisplayName = JSON.parse(
                  serialized_evaluationModel
                );
                handleChange({
                  target: {
                    name: "evaluation_model",
                    value: {
                      key: evaluationModelData?.key,
                      display_name: evaluationModelData?.display_name,
                    },
                  },
                });
              }}
              value={evaluation_model?.display_name || undefined}
              showArrow={true}
              size="large"
              showSearch={true}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select evaluation model from here..."
            >
              {evaluationModelOptions.map((option: KeyAndDisplayName) => (
                <Option key={option?.key} value={JSON.stringify(option)}>
                  {option?.display_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="modal__row">
          <Col span={11}>
            <label>Enter No. Of Direct Report</label>
            <Input
              placeholder="Enter no of direct report here..."
              size="large"
              name={"noOfDirectReports"}
              onChange={handleChange}
              value={noOfDirectReports || ""}
            />
          </Col>
        </Row>

        <div className="sub-heading mb-32">Create Job & Job Function</div>
        <Row className="modal__row" justify="space-between">
          <Col span={11}>
            <label>Job Title</label>
            <Input
              placeholder="Enter Job Title here..."
              size="large"
              name={"jobTitle"}
              onChange={handleChange}
              value={jobTitle || ""}
            />
          </Col>
        </Row>
        <Row className="modal__row" justify="space-between">
          <Col span={11}>
            <label>Job Function</label>
            <Select
              loading={isLoadingJobFunctions}
              size="large"
              showArrow
              placeholder="Select job function from here..."
              showSearch={false}
              onChange={(serialized_jobFunction: string) => {
                const jobFunction = JSON.parse(serialized_jobFunction);
                handleChange({
                  target: {
                    name: "job_function",
                    value: { id: jobFunction?.id, name: jobFunction?.name },
                  },
                });
                dispatch(
                  handleJobDetails({
                    sub_job_function: null,
                  })
                );
                setSubJobFunctions(jobFunction?.job_sub_functions);
              }}
              value={job_function?.name || undefined}
            >
              {jobFunctions?.map((jobFunction: Partial<IJobFunctionItem>) => (
                <Option
                  key={jobFunction?.id}
                  value={JSON.stringify(jobFunction)}
                >
                  {jobFunction?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label>Sub-Job Function</label>
            <Select
              disabled={!job_function?.id}
              size="large"
              showArrow
              placeholder="Select sub-job function from here..."
              showSearch={false}
              onChange={(serialized_jobSubFunction: string) => {
                const jobSubFunction = JSON.parse(serialized_jobSubFunction);
                handleChange({
                  target: {
                    name: "sub_job_function",
                    value: {
                      id: jobSubFunction?.id,
                      name: jobSubFunction?.name,
                    },
                  },
                });
              }}
              value={sub_job_function?.name || undefined}
            >
              {subJobFunctions
                ?.slice()
                ?.sort(function (a: any, b: any) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map(({ id, name }: ISubJobFunctionItem) => (
                  <Option key={id} value={JSON.stringify({ id, name })}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default AddJob;
