import { ReactComponent as UsersIcon } from "@assets/images/users.svg";
import { ReactComponent as CompaniesIcon } from "@assets/images/companies.svg";
import { paths, permissions } from "@router";
import { IJobGrade } from "@/types";
import {
  checkPermission,
  checkRoleBasedAuthorization,
  getCompanyId,
} from "@/utils";
import { VIEW_JOB_EVALUATION } from "@/router/permissions";
import { UserRoles } from "@/constants";
import { ReactComponent as ClientSubAdminsIcon } from "@assets/images/client-sub-admins-icon.svg";

const { client } = paths;

export interface IConfig {
  title: string;
  icon: any;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
}

export const admin_config: Array<IConfig> = [];

export const client_config = (jobGrades: IJobGrade[]): Array<IConfig> => [
  {
    title: "Job Tool",
    icon: UsersIcon,
    client: true,
    path: [
      client.jobEvaluation.listing,
      client.jobEvaluation.create(),
      client.jobEvaluation.summary(),
      client.jobGradeDefination.listing,
    ],

    sub: checkRoleBasedAuthorization([UserRoles.TOM_MASTER_USER])
      ? [
          {
            title: "Grade Defination",
            icon: CompaniesIcon,
            path: client.jobGradeDefination.listing,
            permission: permissions.VIEW_OFFER,
          },
          {
            title: "Job Evaluation",
            icon: CompaniesIcon,
            path: client.jobEvaluation.listing,
            permission: permissions.VIEW_JOB_EVALUATION,
          },
        ]
      : checkRoleBasedAuthorization([UserRoles.COMPANY_SUPER_USER])
      ? [
          {
            title: "Job Evaluation",
            icon: CompaniesIcon,
            path: client.jobEvaluation.listing,
            permission: permissions.VIEW_JOB_EVALUATION,
          },
        ]
      : [],
  },
  // ...(!checkRoleBasedAuthorization([
  //   UserRoles.COMPANY_SUPER_USER,
  //   UserRoles.TOM_MASTER_USER,
  // ])
  //   ? []
  //   : [
  //       {
  //         title: "Users",
  //         icon: UsersIcon,
  //         path: [
  //           client.company.profile.details(),
  //           paths.client.company.profile.businessUnits.listing(),
  //           paths.client.company.profile.regions(),
  //         ],
  //         sub: [
  //           {
  //             title: "Sub-Admins",
  //             icon: ClientSubAdminsIcon,
  //             path: client.sub_admins.listing,
  //             permission: permissions.VIEW_COMPANY_USER,
  //           },
  //           {
  //             title: "Company",
  //             icon: CompaniesIcon,
  //             path: client.company.profile.details(`${getCompanyId()}`),
  //           },
  //         ],
  //       },
  //     ]),
];
