import { UserRoles } from "@/constants";
import { store } from "@store";

export const checkPermission = (permission: string | undefined | string[]) => {
  const permissions = store?.getState()?.auth?.permissions;
  if (permissions?.includes("all")) return true;
  if (!permission) return true;
  if (Array.isArray(permission))
    return permission?.some(x => permissions?.includes(x));

  return permissions?.includes(permission!);
};

export const checkAuthenticationBasedOnAppAccess = (): boolean => {
  const applications = store?.getState()?.auth?.applications;
  const AppName = process.env.REACT_APP_APPLICATION || "JE";
  const appAccessDetails = applications.some(
    (eachApp: any) => eachApp.enum === AppName
  );
  //Already user Id Found, just need to check the access of Application
  return appAccessDetails;
};

export const checkRoleBasedAuthorization = (
  allowedUserRoles: UserRoles[]
): boolean => {
  const userStored: UserRoles = store?.getState()?.auth?.user?.role!;

  return allowedUserRoles.includes(userStored);
};

export const getCompanyId = () => store?.getState()?.auth?.user?.company?.id!;
