import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Upload,
  Card,
  message,
  Space,
} from "antd";
import { useHistory } from "react-router-dom";

import "./createJobEvaluation.less";
import { useTypedSelector } from "@hooks";
import { useDispatch } from "react-redux";
import {
  handleDecisionMakingImpact,
  handleFinancialResponsibility,
  handleJobDetails,
  handleKnowledgeSkillAndApplication,
  handleProblemSolvingSolutioning,
  handleStakeHolderManagement,
  KeyAndDisplayName,
} from "@/store/jobEvaluation";
import {
  ErrorServices,
  IUpdateJobEvaluation,
  useCreateJobEvaluationMutation,
  useFetchAllApplicationsQuery,
  useFetchAllComplexitiesQuery,
  useFetchAllImpactOfDecisionMakingQuery,
  useFetchAllKnowledgeAndSkillsQuery,
  useFetchAllNatureOfCommunicationQuery,
  useFetchAllNonFinancialResponsibilityQuery,
  useFetchAllProblemSolvingAndSolutioningQuery,
  useFetchAllRevenueResponsibilityQuery,
  useFetchAllRoleImpactOnOrganisationQuery,
  useUpdateJobEvaluationMutation,
  useUploadJobEvaluationFormsMutation,
  useFetchAllBusinessUnitsQuery,
} from "@services";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";
import { EvaluationModelKeys, evaluationModelOptions } from "@/constants";

import JobEvaluationDataProvider from "../JobEvaluationDataProvider/JobEvaluationDataProvider";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { IBusinessUnitItem, IRegion } from "@types";

const { Option } = Select;

const CreateJobEvaluation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fileListOrgChart, setFileListOrgChart] = useState<any[]>([]);
  const [fileListJEform, setFileListJEform] = useState<any[]>([]);

  const [uploading, setUploading] = useState(false);
  const [uploadJobEvaluationForms, { isLoading }] =
    useUploadJobEvaluationFormsMutation();

  const handleUpload = async () => {
    const formData = new FormData();
    fileListOrgChart.forEach(file => {
      formData.append("organization_chart", file);
    });

    fileListJEform.forEach(file => {
      formData.append("je_form", file);
    });

    try {
      const payload = await uploadJobEvaluationForms({
        company_id,
        job_evaluation_id,
        body: formData,
      }).unwrap();

      if (payload.message) {
        message.success(payload.message);
        // dispatch(updateValidationId(payload.result));
        // dispatch(resetEquipmentId());
        // setFileList([]);
      }
    } catch (error) {
      message.error("Unable to process request");
    }
  };

  const uploadOrgChartProps: UploadProps = {
    onRemove: (file: any) => {
      const index = fileListOrgChart.indexOf(file);
      const newFileList = fileListOrgChart.slice();
      newFileList.splice(index, 1);
      setFileListOrgChart(newFileList);
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
        return false;
      }
      if (fileListOrgChart.length) {
        message.warning("Only one file can be uploaded at a time");
        return false;
      }
      fileListOrgChart.length === 0 &&
        setFileListOrgChart([...fileListOrgChart, file]);

      return false;
    },
    fileList: fileListOrgChart,
  };

  const uploadJEformProps: UploadProps = {
    onRemove: (file: any) => {
      const index = fileListJEform.indexOf(file);
      const newFileList = fileListJEform.slice();
      newFileList.splice(index, 1);
      setFileListJEform(newFileList);
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        message.error("File must be smaller than 5MB!");
        return false;
      }
      if (fileListJEform.length) {
        message.warning("Only one file can be uploaded at a time");
        return false;
      }
      fileListJEform.length === 0 &&
        setFileListJEform([...fileListJEform, file]);

      return false;
    },
    fileList: fileListJEform,
  };

  const { user } = useTypedSelector(state => state.auth);

  const company_id = user?.company?.id || 0;

  const {
    evaluationInformation,
    jobDetails,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
    id: jobDetails_id,
    status,
    je_form,
    organization_chart,
  } = useTypedSelector(state => state?.jobEvaluation);
  const {
    jobTitle,
    job_function,
    sub_job_function,
    evaluation_model,
    region,
    business_unit,
    country_id,
  } = jobDetails;
  const {
    knowledge_and_skill,
    application,
    minimum_level_education,
    minimum_level_education_reason,
    educational_degree,
  } = knowledgeSkillAndApplication;
  const { problem_solving_solutioning } = problemSolvingSolutioning;
  const { communication_nature, complexity } = stakeHolderManagement;
  const { role_impact_on_organisation, impact_of_decision } =
    decisionMakingImpact;
  const { non_financial_responsibility, revenue_responsibility } =
    financialResponsibility;

  const disabled =
    !knowledge_and_skill?.key ||
    !application?.key ||
    !minimum_level_education ||
    !minimum_level_education_reason ||
    !educational_degree ||
    !problem_solving_solutioning?.key ||
    !communication_nature?.key ||
    !complexity?.key ||
    !role_impact_on_organisation?.key ||
    !impact_of_decision?.key ||
    !evaluation_model?.key ||
    (evaluation_model?.key === EvaluationModelKeys.NON_FINANCIAL &&
      !non_financial_responsibility?.key) ||
    (evaluation_model?.key !== EvaluationModelKeys.NON_FINANCIAL &&
      !revenue_responsibility?.key);

  const {
    data: knowledgeAndSkillsData,
    isLoading: isLoadingknowledgeAndSkillsData,
  } = useFetchAllKnowledgeAndSkillsQuery(null);
  const { data: knowledgeAndSkillOptions } = knowledgeAndSkillsData || {
    data: [],
  };

  const { data: applicationsData, isLoading: isLoadingApplicationsData } =
    useFetchAllApplicationsQuery(null);
  const { data: applicationOptions } = applicationsData || { data: [] };

  const {
    data: problemSolvingAndSolutioningData,
    isLoading: isLoadingProblemSolvingAndSolutioningData,
  } = useFetchAllProblemSolvingAndSolutioningQuery(null);
  const { data: problemSolvingAndSolutioningOptions } =
    problemSolvingAndSolutioningData || {
      data: [],
    };

  const {
    data: natureOfCommunicationData,
    isLoading: isLoadingNatureOfCommunicationData,
  } = useFetchAllNatureOfCommunicationQuery(null);
  const { data: natureOfCommunicationOptions } = natureOfCommunicationData || {
    data: [],
  };

  const { data: complexityData, isLoading: isLoadingComplexityData } =
    useFetchAllComplexitiesQuery(null);
  const { data: complexityOptions } = complexityData || {
    data: [],
  };

  const {
    data: roleImpactOnOrganisationData,
    isLoading: isLoadingRoleImpactOnOrganisationData,
  } = useFetchAllRoleImpactOnOrganisationQuery(null);
  const { data: roleImpactOnOrganisationOptions } =
    roleImpactOnOrganisationData || {
      data: [],
    };

  const {
    data: impactOfDecisionMakingData,
    isLoading: isLoadingImpactOfDecisionMakingData,
  } = useFetchAllImpactOfDecisionMakingQuery(null);
  const { data: impactOfDecisionMakingOptions } =
    impactOfDecisionMakingData || {
      data: [],
    };

  const {
    data: nonFinancialResponsibilityData,
    isLoading: isLoadingNonFinancialResponsibilityData,
  } = useFetchAllNonFinancialResponsibilityQuery(null);
  const { data: nonFinancialResponsibilityOptions } =
    nonFinancialResponsibilityData || {
      data: [],
    };

  const {
    data: revenueResponsibilityData,
    isLoading: isLoadingRevenueResponsibility,
  } = useFetchAllRevenueResponsibilityQuery(null);
  const { data: revenueResponsibilityOptions } = revenueResponsibilityData || {
    data: [],
  };

  const job_evaluation_id = jobDetails_id || 0;

  const [updateJobEvaluation, { isLoading: isLoadingUpdateJobEvaluation }] =
    useUpdateJobEvaluationMutation();
  const [createJobEvaluation, { isLoading: isLoadingCreateJobEvaluation }] =
    useCreateJobEvaluationMutation();

  const handleChangeJobDetailsStep = (event: any) => {
    dispatch(
      handleJobDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleChangeKnowledgeSkillStep = (event: any) => {
    dispatch(
      handleKnowledgeSkillAndApplication({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeProblemSolvingStep = (event: any) => {
    dispatch(
      handleProblemSolvingSolutioning({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeStakeHolderManagementStep = (event: any) => {
    dispatch(
      handleStakeHolderManagement({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeDecisionMakingStep = (event: any) => {
    dispatch(
      handleDecisionMakingImpact({
        [event.target.name]: event.target.value,
      })
    );
  };
  const handleChangeFinancialResponsibilityStep = (event: any) => {
    dispatch(
      handleFinancialResponsibility({
        [event.target.name]: event.target.value,
      })
    );
  };

  useEffect(() => {
    const body = {
      job_title: jobDetails.jobTitle || undefined,
      department_name: jobDetails.departmentName || undefined,
      evaluation_model: jobDetails.evaluation_model?.key || undefined,
      no_direct_report: jobDetails.noOfDirectReports || undefined,
      business_unit: jobDetails?.business_unit,
      country: jobDetails?.country_id,
      region: jobDetails?.region,
      knowledge_skills:
        knowledgeSkillAndApplication.knowledge_and_skill?.key || undefined,
      knowledge_skills_application:
        knowledgeSkillAndApplication.application?.key || undefined,
      minimum_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education || undefined,
      why_is_this_level_of_education_required:
        knowledgeSkillAndApplication.minimum_level_education_reason ||
        undefined,
      area_of_specializations_or_degree:
        knowledgeSkillAndApplication.educational_degree || undefined,
      problem_solving_solutioning:
        problemSolvingSolutioning.problem_solving_solutioning?.key || undefined,
      nature_of_communication:
        stakeHolderManagement.communication_nature?.key || undefined,
      complexity: stakeHolderManagement.complexity?.key || undefined,
      role_impact_on_organization:
        decisionMakingImpact.role_impact_on_organisation?.key || undefined,
      impact_of_decision_making:
        decisionMakingImpact.impact_of_decision?.key || undefined,
      non_financial_responsibility:
        financialResponsibility.non_financial_responsibility?.key || undefined,
      revenue_responsibility:
        financialResponsibility.revenue_responsibility?.key || undefined,
    };
    if (company_id && job_evaluation_id) {
      updateJobEvaluation({
        company_id,
        job_evaluation_id,
        body,
      }).unwrap();
    }
  }, [
    dispatch,
    jobDetails,
    evaluationInformation,
    knowledgeSkillAndApplication,
    problemSolvingSolutioning,
    stakeHolderManagement,
    decisionMakingImpact,
    financialResponsibility,
  ]);

  const onSubmit = async () => {
    try {
      const body: IUpdateJobEvaluation = {
        job_title: jobDetails.jobTitle || undefined,
        department_name: jobDetails.departmentName || undefined,
        evaluation_model: jobDetails.evaluation_model?.key || undefined,
        no_direct_report: jobDetails.noOfDirectReports || undefined,
        business_unit: jobDetails?.business_unit,
        country: jobDetails?.country_id,
        region: jobDetails?.region,
        knowledge_skills:
          knowledgeSkillAndApplication.knowledge_and_skill?.key || undefined,
        knowledge_skills_application:
          knowledgeSkillAndApplication.application?.key || undefined,
        minimum_level_of_education_required:
          knowledgeSkillAndApplication.minimum_level_education || undefined,
        why_is_this_level_of_education_required:
          knowledgeSkillAndApplication.minimum_level_education_reason ||
          undefined,
        area_of_specializations_or_degree:
          knowledgeSkillAndApplication.educational_degree || undefined,
        problem_solving_solutioning:
          problemSolvingSolutioning.problem_solving_solutioning?.key ||
          undefined,
        nature_of_communication:
          stakeHolderManagement.communication_nature?.key || undefined,
        complexity: stakeHolderManagement.complexity?.key || undefined,
        role_impact_on_organization:
          decisionMakingImpact.role_impact_on_organisation?.key || undefined,
        impact_of_decision_making:
          decisionMakingImpact.impact_of_decision?.key || undefined,
        non_financial_responsibility:
          financialResponsibility.non_financial_responsibility?.key ||
          undefined,
        revenue_responsibility:
          financialResponsibility.revenue_responsibility?.key || undefined,
      };

      const jobEvaluationResp = await createJobEvaluation({
        company_id,
        job_evaluation_id,
        body,
      }).unwrap();
      const statue = status;
      const jobId = jobDetails_id ? jobDetails_id.toString() : "";
      jobId && history.push(paths.client.jobEvaluation.summary(jobId));

      showSuccessPopup({
        title: "Job Evaluation Created",
        desc: `You have successfully evaluated a job. Job Grade : ${jobEvaluationResp?.data?.evaluation_result}`,
      });
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const gridStyle: React.CSSProperties = {
    width: "50%",
    textAlign: "left",
  };

  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};

  return (
    <JobEvaluationDataProvider>
      <div className="container">
        <Row>
          <Col span={24}>
            <div className="main-heading mb-16">{`Job Evaluation`}</div>
          </Col>
        </Row>
        <div className="addCompany">
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">Evaluation Information</div>
            </Row>
            <div className="offers__form__fields">
              <Row
                justify="space-between"
                className="mb-32"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                <Col span={9}>
                  <label>Evaluation Model</label>
                  <Select
                    onChange={(serialized_evaluationModel: string) => {
                      const evaluationModelData: KeyAndDisplayName = JSON.parse(
                        serialized_evaluationModel
                      );

                      handleChangeJobDetailsStep({
                        target: {
                          name: "evaluation_model",
                          value: {
                            key: evaluationModelData?.key,
                            display_name: evaluationModelData?.display_name,
                          },
                        },
                      });

                      if (
                        evaluationModelData?.key ===
                        EvaluationModelKeys.Financial
                      ) {
                        dispatch(
                          handleFinancialResponsibility({
                            non_financial_responsibility: null,
                          })
                        );
                      }
                    }}
                    value={
                      jobDetails.evaluation_model?.display_name || undefined
                    }
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select evaluation model from here..."
                  >
                    {evaluationModelOptions.map((option: KeyAndDisplayName) => (
                      <Option key={option?.key} value={JSON.stringify(option)}>
                        {option?.display_name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Card
                    size="small"
                    style={{ minWidth: 400, marginTop: 25 }}
                    title="Upload Job Evaluation Form and Organizational Chart"
                  >
                    <Row
                      justify="space-between"
                      className="mb-32"
                      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    >
                      <Col span={8}>
                        <Upload {...uploadJEformProps} maxCount={1}>
                          <div className="space-align-container">
                            <div className="space-align-block">
                              <Space align="center">
                                <Button icon={<UploadOutlined />}>
                                  Select JE Form
                                </Button>
                                {je_form && je_form.length && (
                                  <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={() =>
                                      window.open(`${je_form}`, "_blank")
                                    }
                                  />
                                )}
                              </Space>
                            </div>
                          </div>
                        </Upload>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={15}>
                        {" "}
                        <Upload {...uploadOrgChartProps} maxCount={1}>
                          <div className="space-align-container">
                            <div className="space-align-block">
                              <Space align="center">
                                <Button icon={<UploadOutlined />}>
                                  Select Organization Chart
                                </Button>
                                {organization_chart &&
                                  organization_chart.length && (
                                    <Button
                                      type="primary"
                                      icon={<DownloadOutlined />}
                                      onClick={() =>
                                        window.open(
                                          `${organization_chart}`,
                                          "_blank"
                                        )
                                      }
                                    ></Button>
                                  )}
                              </Space>
                            </div>
                          </div>
                        </Upload>
                      </Col>
                    </Row>

                    <Card.Grid
                      hoverable={false}
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <Button
                        type="primary"
                        onClick={() => {
                          handleUpload();
                        }}
                        disabled={
                          fileListOrgChart.length === 0 &&
                          fileListJEform.length === 0
                        }
                        loading={isLoading}
                      >
                        {isLoading ? "Uploading" : "Start Upload"}
                      </Button>
                    </Card.Grid>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          {/* Job Details Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">Job Details</div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Job Title</label>
                  <Input
                    size="large"
                    name={"jobTitle"}
                    value={jobTitle || ""}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Job Function</label>
                  <Select
                    disabled={true}
                    size="large"
                    showArrow
                    showSearch={false}
                    value={job_function?.name || undefined}
                  ></Select>
                </Col>
                <Col span={9}>
                  <label>Sub-Job Function</label>
                  <Select
                    disabled={true}
                    size="large"
                    showArrow
                    showSearch={false}
                    value={sub_job_function?.name || undefined}
                  ></Select>
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Business Unit</label>
                  <Select
                    disabled={true}
                    value={business_unit || undefined}
                    showArrow={true}
                    size="large"
                  >
                    {businessUnits?.map(
                      (option: Partial<IBusinessUnitItem>) => (
                        <Option key={option?.id} value={option?.id!}>
                          {option?.name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
                <Col span={9}>
                  <label>Region</label>
                  <Select
                    disabled={true}
                    value={region || undefined}
                    showArrow={true}
                    size="large"
                  >
                    {businessUnits
                      ?.filter((elm: any) => elm.id === business_unit)[0]
                      ?.regions?.map((option: Partial<IRegion>) => (
                        <Option key={option?.id} value={option?.id!}>
                          {option?.name}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Country</label>
                  <Select
                    disabled={true}
                    value={country_id || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                  >
                    {businessUnits
                      ?.filter((elm: any) => elm.id === business_unit)[0]
                      ?.regions?.filter((elm: any) => elm.id === region)[0]
                      ?.countries?.map((option: Partial<IRegion>) => (
                        <Option key={option?.id} value={option?.id!}>
                          {option?.name}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            </div>
          </div>
          {/* Knowledge, Skill and Application Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">
                Knowledge, Skill and Application
              </div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Knowledge And Skills</label>
                  <Select
                    loading={isLoadingknowledgeAndSkillsData}
                    onChange={(
                      serialized_knowledgeSkillAndApplication: string
                    ) => {
                      const knowledgeSkillData = JSON.parse(
                        serialized_knowledgeSkillAndApplication
                      );
                      handleChangeKnowledgeSkillStep({
                        target: {
                          name: "knowledge_and_skill",
                          value: {
                            key: knowledgeSkillData?.key,
                            display_name: knowledgeSkillData?.display_name,
                          },
                        },
                      });
                    }}
                    value={knowledge_and_skill?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select role type from here..."
                  >
                    {knowledgeAndSkillOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
                <Col span={9}>
                  <label>Application</label>
                  <Select
                    loading={isLoadingApplicationsData}
                    onChange={(serialized_application: string) => {
                      const applicationData: KeyAndDisplayName = JSON.parse(
                        serialized_application
                      );
                      handleChangeKnowledgeSkillStep({
                        target: {
                          name: "application",
                          value: {
                            key: applicationData?.key,
                            display_name: applicationData?.display_name,
                          },
                        },
                      });
                    }}
                    value={application?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select role type from here..."
                  >
                    {applicationOptions.map((option: KeyAndDisplayName) => (
                      <Option key={option?.key} value={JSON.stringify(option)}>
                        {option?.display_name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={24}>
                  <label>Minimum level of education required</label>
                  <Input
                    placeholder="Enter here..."
                    size="large"
                    name={"minimum_level_education"}
                    onChange={handleChangeKnowledgeSkillStep}
                    value={minimum_level_education || ""}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={24}>
                  <label>Why is this level of education required</label>
                  <Input
                    placeholder="Enter here..."
                    size="large"
                    name={"minimum_level_education_reason"}
                    onChange={handleChangeKnowledgeSkillStep}
                    value={minimum_level_education_reason || ""}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="mb-32">
                <Col span={24}>
                  <label>Area of specialization/degree</label>
                  <Input
                    placeholder="Enter here..."
                    size="large"
                    name={"educational_degree"}
                    onChange={handleChangeKnowledgeSkillStep}
                    value={educational_degree || ""}
                  />
                </Col>
              </Row>
            </div>
          </div>
          {/* Problem Solving And Solutioning Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">Problem Solving And Solutioning</div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Problem Solving And Solutioning</label>
                  <Select
                    loading={isLoadingProblemSolvingAndSolutioningData}
                    onChange={(
                      serialized_problemSolvingSolutioning: string
                    ) => {
                      const problemSolvingSolutioningData: KeyAndDisplayName =
                        JSON.parse(serialized_problemSolvingSolutioning);
                      handleChangeProblemSolvingStep({
                        target: {
                          name: "problem_solving_solutioning",
                          value: {
                            key: problemSolvingSolutioningData?.key,
                            display_name:
                              problemSolvingSolutioningData?.display_name,
                          },
                        },
                      });
                    }}
                    value={
                      problem_solving_solutioning?.display_name || undefined
                    }
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {problemSolvingAndSolutioningOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
              </Row>
            </div>
          </div>
          {/* StakeHolder Management Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">StakeHolder Management</div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Nature of communication</label>
                  <Select
                    loading={isLoadingNatureOfCommunicationData}
                    onChange={(serialized_communicationNature: string) => {
                      const communicationNatureData: KeyAndDisplayName =
                        JSON.parse(serialized_communicationNature);
                      handleChangeStakeHolderManagementStep({
                        target: {
                          name: "communication_nature",
                          value: {
                            key: communicationNatureData?.key,
                            display_name: communicationNatureData?.display_name,
                          },
                        },
                      });
                    }}
                    value={communication_nature?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {natureOfCommunicationOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
                <Col span={9}>
                  <label>Complexity</label>
                  <Select
                    loading={isLoadingComplexityData}
                    onChange={(serialized_complexity: string) => {
                      const complexityData: KeyAndDisplayName = JSON.parse(
                        serialized_complexity
                      );
                      handleChangeStakeHolderManagementStep({
                        target: {
                          name: "complexity",
                          value: {
                            key: complexityData?.key,
                            display_name: complexityData?.display_name,
                          },
                        },
                      });
                    }}
                    value={complexity?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {complexityOptions.map((option: KeyAndDisplayName) => (
                      <Option key={option?.key} value={JSON.stringify(option)}>
                        {option?.display_name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </div>
          </div>
          {/* Decision Making And Impact Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">Decision Making And Impact</div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Role Impact On Organisation</label>
                  <Select
                    loading={isLoadingRoleImpactOnOrganisationData}
                    onChange={(serialized_roleImpactOnOrganisation: string) => {
                      const roleImpactOnOrganisationData: KeyAndDisplayName =
                        JSON.parse(serialized_roleImpactOnOrganisation);
                      handleChangeDecisionMakingStep({
                        target: {
                          name: "role_impact_on_organisation",
                          value: {
                            key: roleImpactOnOrganisationData?.key,
                            display_name:
                              roleImpactOnOrganisationData?.display_name,
                          },
                        },
                      });
                    }}
                    value={
                      role_impact_on_organisation?.display_name || undefined
                    }
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {roleImpactOnOrganisationOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
                <Col span={9}>
                  <label>Impact Of Decision Making</label>
                  <Select
                    loading={isLoadingImpactOfDecisionMakingData}
                    onChange={(serialized_impactOfDecision: string) => {
                      const impactOfDecisionData: KeyAndDisplayName =
                        JSON.parse(serialized_impactOfDecision);
                      handleChangeDecisionMakingStep({
                        target: {
                          name: "impact_of_decision",
                          value: {
                            key: impactOfDecisionData?.key,
                            display_name: impactOfDecisionData?.display_name,
                          },
                        },
                      });
                    }}
                    value={impact_of_decision?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {impactOfDecisionMakingOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
              </Row>
            </div>
          </div>
          {/* Financial Responsibility Step */}
          <div className="addCompany__content">
            <Row className="offers__form__header">
              <div className="sub-heading">Financial Responsibility</div>
            </Row>
            <div className="offers__form__fields">
              <Row justify="space-between" className="mb-32">
                <Col span={9}>
                  <label>Non Financial Responsibility</label>
                  <Select
                    disabled={jobDetails?.evaluation_model?.key === "financial"}
                    onChange={(
                      serialized_nonFinancialResponsibility: string
                    ) => {
                      const nonFinancialResponsibilityData: KeyAndDisplayName =
                        JSON.parse(serialized_nonFinancialResponsibility);
                      handleChangeFinancialResponsibilityStep({
                        target: {
                          name: "non_financial_responsibility",
                          value: {
                            key: nonFinancialResponsibilityData?.key,
                            display_name:
                              nonFinancialResponsibilityData?.display_name,
                          },
                        },
                      });
                    }}
                    value={
                      non_financial_responsibility?.display_name || undefined
                    }
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {nonFinancialResponsibilityOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
                <Col span={9}>
                  <label>Revenue Responsibility (USD)</label>
                  <Select
                    disabled={
                      jobDetails?.evaluation_model?.key === "non_financial"
                    }
                    loading={isLoadingRevenueResponsibility}
                    onChange={(serialized_revenueResponsibility: string) => {
                      const revenueResponsibilityData: KeyAndDisplayName =
                        JSON.parse(serialized_revenueResponsibility);
                      handleChangeFinancialResponsibilityStep({
                        target: {
                          name: "revenue_responsibility",
                          value: {
                            key: revenueResponsibilityData?.key,
                            display_name:
                              revenueResponsibilityData?.display_name,
                          },
                        },
                      });
                    }}
                    value={revenue_responsibility?.display_name || undefined}
                    showArrow={true}
                    size="large"
                    showSearch={true}
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select factors from here..."
                  >
                    {revenueResponsibilityOptions.map(
                      (option: KeyAndDisplayName) => (
                        <Option
                          key={option?.key}
                          value={JSON.stringify(option)}
                        >
                          {option?.display_name}
                        </Option>
                      )
                    )}
                  </Select>
                </Col>
              </Row>
            </div>
          </div>
          <div className="addCompany__content">
            <div className="offers__form__fields">
              {
                <Row className="offers__form__fields__btns">
                  <Button
                    disabled={disabled}
                    onClick={() => onSubmit()}
                    size="large"
                    className="secondary-btn"
                  >
                    Save Evaluation Details
                  </Button>
                  <Button
                    onClick={() =>
                      history.push(paths.client.jobEvaluation.listing)
                    }
                    size="large"
                    className="secondary-btn"
                  >
                    Cancel
                  </Button>
                </Row>
              }
            </div>
          </div>
        </div>
      </div>
    </JobEvaluationDataProvider>
  );
};

export default CreateJobEvaluation;
