import { FC } from "react";
import { Row } from "antd";
import { NavLink } from "react-router-dom";

import "./tabs.less";
import { Tab } from "./types";

interface ITabs {
  tabs: Tab<string>[];
  param?: string;
  paramValue?: string;
  route?: boolean;
  selectedTabId?: number;
  onClickTab?: (id: number) => void;
}

const Tabs: FC<ITabs> = ({
  tabs,
  param,
  paramValue,
  route = true,
  selectedTabId,
  onClickTab,
}) => {
  if (!route)
    return (
      <Row>
        {tabs.map(({ id, title }: Tab<number>) => (
          <div
            onClick={() => onClickTab!(id)}
            className={`tab ${id === selectedTabId && "tab--active"}`}
            key={id}
          >
            {title}
          </div>
        ))}
      </Row>
    );

  return (
    <Row>
      {tabs.map(({ id, path, title }: Tab<string>) => (
        <NavLink
          exact={true}
          className="tab"
          activeClassName="tab--active"
          key={id}
          to={`${path!.replace(param!, paramValue!)}`}
        >
          {title}
        </NavLink>
      ))}
    </Row>
  );
};

export default Tabs;
