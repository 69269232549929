import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),

  endpoints: builder => ({
    login: builder.mutation({
      query: ({ body, is_company }) => ({
        url: `/v2/auth/login/?is_company=${is_company}`,
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation({
      query: body => ({
        url: "/auth/forgot-password/",
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: body => ({
        url: "/auth/change-password/",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: body => ({
        url: "/auth/logout/",
        method: "POST",
        body,
      }),
    }),
    verify: builder.mutation({
      query: ({ selected_application, companyId }) => ({
        url: `/v2/auth/verify-user/?application=${selected_application}&company_id=${companyId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useVerifyMutation,
} = authApi;
