import { paths } from "@router";

export const tabs = [
  {
    id: 1,
    title: "Company details",
    path: paths.client.company.profile.details(),
  },
  {
    id: 2,
    title: "Business units",
    path: paths.client.company.profile.businessUnits.listing(),
  },
  {
    id: 3,
    title: "Regions",
    path: paths.client.company.profile.regions(),
  },
];
