import { ApiMethodes, endpoints } from "@/constants";
import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export interface ICreateJobGradeMapping {
  grade_point_range: string;
  grade_point_value: string;
  company: string;
}

export const jobGradeMappingApi = createApi({
  reducerPath: "jobGradeMappingApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  tagTypes: [
    "JobGradeMappingCompanies",
    "GradePointRanges",
    "GradePointForCompanies",
  ],

  endpoints: builder => ({
    /**
     * @Services - job-grade-mapping
     */
    fetchAllJobGradeMappingCompanies: builder.query({
      query: () => ({
        url: endpoints.JOB_GRADE_MAPPING.COMPANIES_DD(),
        method: ApiMethodes.GET,
      }),
      providesTags: ["JobGradeMappingCompanies"],
    }),

    fetchGradePointRangesByCompanyId: builder.query({
      query: ({ company_id }: { company_id: string }) => ({
        url: endpoints.JOB_GRADE_MAPPING.GRADE_POINT_RANGES_DD(company_id),
        method: ApiMethodes.GET,
      }),
      providesTags: ["GradePointRanges"],
    }),
    fetchAllGradePointsForCompanies: builder.query({
      query: () => ({
        url: endpoints.JOB_GRADE_MAPPING.LIST(),
        method: ApiMethodes.GET,
      }),
      providesTags: ["GradePointForCompanies"],
    }),
    createJobGradeMapping: builder.mutation({
      query: (payload: { body: ICreateJobGradeMapping }) => ({
        url: endpoints.JOB_GRADE_MAPPING.CREATE(),
        method: ApiMethodes.POST,
        body: payload.body,
      }),
      invalidatesTags: ["GradePointRanges", "GradePointForCompanies"],
    }),
  }),
});

export const {
  useFetchAllJobGradeMappingCompaniesQuery,
  useFetchGradePointRangesByCompanyIdQuery,
  useFetchAllGradePointsForCompaniesQuery,
  useCreateJobGradeMappingMutation,
} = jobGradeMappingApi;
