import { useState } from "react";
import { Col, Row, TableColumnsType, Tag, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./jobEvaluation.less";
import { paths } from "@router";
import { useFetchAllJobEvaluationsQuery } from "@services";
import Table from "@components/Table";
import AddJob from "./AddJob";
import { IJobEvaluationDataResponse } from "@/store/jobEvaluation/types";
import { dateFormat, JobEvaluationInquiryStatus } from "@/constants";
import moment from "moment";
import { useTypedSelector } from "@/hooks";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";

const Jobs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { jobEvaluationListingPage } = useTypedSelector(
    state => state?.jobEvaluation
  );

  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;

  const { data: jobEvaluationsData, isLoading } =
    useFetchAllJobEvaluationsQuery({
      company_id,
      page: jobEvaluationListingPage,
    });
  const { data, pagination } = jobEvaluationsData || {};

  const columns: TableColumnsType<IJobEvaluationDataResponse> = [
    {
      title: "EVALUATION ID",
      // dataIndex: "id",
      key: "id",
      width: 200,
      render: (record: IJobEvaluationDataResponse) => (
        <div className="text-wrap">{record.id}</div>
      ),
    },
    {
      title: "DATE",
      key: "updated_at",
      width: 120,
      render: (record: IJobEvaluationDataResponse) => (
        <div className="text-wrap">{`${moment(record.updated_at).format(
          dateFormat
        )}`}</div>
      ),
    },
    {
      title: "JOB TITLE",
      key: "job_title",
      width: 250,
      render: (record: IJobEvaluationDataResponse) => (
        <div className="text-wrap">{record.job_title}</div>
      ),
    },
    {
      title: "JOB FUNCTION",
      // dataIndex: "job_function",
      key: "job_function",
      width: 150,
      render: (record: IJobEvaluationDataResponse) => (
        <div className="text-wrap">{record.job_function?.name}</div>
      ),
    },
    {
      title: "COUNTRY",
      // dataIndex: "country",
      key: "country",
      width: 150,
      render: (record: IJobEvaluationDataResponse) => (
        <div className="text-wrap">{record.country?.name}</div>
      ),
    },
    {
      title: "SCORE",
      // dataIndex: "evaluation_result",
      key: "evaluation_result",
      width: 150,
      align: "center",
      render: (record: IJobEvaluationDataResponse) => {
        const score = record.evaluation_result ? record.evaluation_result : "-";
        return <div className="text-wrap">{score}</div>;
      },
    },
    {
      title: "JOB EVALUATION STATUS",
      // dataIndex: "status",
      key: "status",
      width: 200,
      align: "center",
      render: (record: IJobEvaluationDataResponse) => {
        const color: { [key: string]: string } = {
          open: "green",
          "in progress": "gold",
          close: "red",
        };
        const key = record.status?.key!;
        return <Tag color={color[key.toLowerCase()]}>{key.toUpperCase()}</Tag>;
      },
    },
  ];

  const onRowClick = (data: IJobEvaluationDataResponse) => {
    const jobId = data.id ? data.id.toString() : "";

    data.status?.key === JobEvaluationInquiryStatus.CLOSE
      ? history.push(paths.client.jobEvaluation.summary(jobId))
      : history.push(paths.client.jobEvaluation.create(jobId));
  };
  const onCloseModal = () => {
    setIsVisible(false);
  };

  const handleTablePageChange = (newPage: number) => {
    dispatch(handlePageChange(newPage));
  };

  return (
    <div className="companies">
      <AddJob isVisible={isVisible} setIsVisible={onCloseModal} />
      <Row>
        <Col span={24}>
          <div className="main-heading mb-16">Job Creation</div>
          <div className="companies__upper mb-20">
            <Button
              onClick={() => {
                dispatch(resetJobDetails);
                setIsVisible(true);
              }}
              type="primary"
            >
              <PlusOutlined /> Create Jobs
            </Button>
          </div>
          <Table
            onRowClick={onRowClick}
            columns={columns}
            data={data}
            isLoading={isLoading}
            pagination={true}
            count={pagination?.count}
            onChangePage={handleTablePageChange}
            page={jobEvaluationListingPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Jobs;
