import { FC } from "react";
import { Route, Switch } from "react-router";

import { RouteWithSubRoutes, IRoute, paths } from "@router";
import { Redirect } from "react-router-dom";
import Jobs from "@/pages/client/JobEvaluation";

const Routes: FC<{ routes: IRoute[] }> = ({ routes }) => {
  return (
    <Switch>
      {routes.map(route => (
        <RouteWithSubRoutes {...route} />
      ))}

      <Route component={Jobs} />
    </Switch>
  );
};

export default Routes;
