import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Form, Select, message } from "antd";
import Table from "@components/Table";
import { useHistory, useParams } from "react-router-dom";
import CountryPhoneInput, {
  CountryPhoneInputValue,
  ConfigProvider,
} from "antd-country-phone-input";
import en from "world_countries_lists/data/en/world.json";

import "./style.less";
import {
  ErrorServices,
  useCreateCompanyUserMutation,
  useFetchCompanyUserByIdQuery,
  useUpdateCompanyUserMutation,
  useFetchAllBusinessUnitsQuery,
  useFetchAllCompanyJobGradesQuery,
  useFetchAllJobFunctionsQuery,
} from "@services";
import { useTypedSelector } from "@hooks";
import PermissionsTable from "./PermissionTable";
import {
  IBusinessUnitItem,
  ICountry,
  IJobFunctionItem,
  IJobGrade,
  IRegion,
} from "@types";

const { Option } = Select;

const CreateSubAdmin = () => {
  let subAdminForm = useRef<any>(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useTypedSelector(state => state.auth);
  const [businessUnitState, setBusinessUnitState] = useState<any[]>([]);
  const [regionState, setRegionState] = useState<any[]>([]);
  const [regions, setRegions] = useState<Partial<IRegion>[]>([]);
  const [countryState, setCountryState] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [grade, setGrade] = useState<any[]>([]);
  const [jobFunctionState, setJobFunctionState] = useState<any[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [permissionArray, setPermissionArray] = useState<string[]>([]);
  const [permissionAPIArray, setPermissionAPIArray] = useState<string[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<any>({
    short: "US",
  });
  const { id: company_id } = user?.company || {};
  const [createCompanyUser, { isLoading }] = useCreateCompanyUserMutation();
  const [updateCompanyUser, { isLoading: isUpdating }] =
    useUpdateCompanyUserMutation();
  const { sub_admin_id } = useParams<{ sub_admin_id?: string }>();
  const { data } = useFetchCompanyUserByIdQuery(
    {
      company_id,
      id: sub_admin_id,
    },
    { skip: !sub_admin_id, refetchOnMountOrArgChange: true }
  );
  const { data: selected_user } = data || {};
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};
  const { data: gradesData, isLoading: isLoadingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: grades } = gradesData || {};
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};

  useEffect(() => {
    if (
      selected_user &&
      !isLoadingBusinessUnits &&
      !isLoadingGrades &&
      !isLoadingJobFunctions
    ) {
      const apiBusiness = [
        ...selected_user.role.metafield.business_units.map(
          (elm: any) => elm.name
        ),
      ];
      const extractRegionsFromBU = businessUnits.filter((elm: any) =>
        apiBusiness.includes(elm.name)
      );
      var tempRegions: string[] = [];
      var tempCountries: string[] = [];
      extractRegionsFromBU.forEach((elm: any) => {
        tempRegions.push(...elm.regions);
      });
      var distinctRegionsArr: object[] = Object.values(
        tempRegions.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setRegions(distinctRegionsArr);
      distinctRegionsArr.forEach((elm: any) => {
        tempCountries.push(...elm.countries);
      });
      var distinctCountriesArr: object[] = Object.values(
        tempCountries.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setCountries(distinctCountriesArr);

      setFirstName(selected_user.first_name);
      setLastName(selected_user.last_name);
      setPhoneNumber({
        code: selected_user?.phone_code,
        phone: selected_user?.phone_number,
      });
      setEmail(selected_user.email);
      setBusinessUnitState(apiBusiness);
      setRegionState([
        ...selected_user.role.metafield.regions.map((elm: any) => elm.name),
      ]);
      setCountryState([
        ...selected_user.role.metafield.countries.map((elm: any) => elm.name),
      ]);
      setGrade([
        ...selected_user.role.metafield.grades.map((elm: any) => elm.grade),
      ]);
      setJobFunctionState([
        ...selected_user.role.metafield.job_functions.map(
          (elm: any) => elm.name
        ),
      ]);
      setPermissionAPIArray(
        selected_user.role.permissions.map((elm: any) => elm.name)
      );
      if (selected_user.role.name === "COMPANY_SUPER_USER") {
        setRole(selected_user.role.name);
      } else {
        // setIsCustomRole(true);
        setRole(selected_user.role.name);
      }

      const values = {
        first_name: selected_user.first_name,
        last_name: selected_user.last_name,
        phoneNumber: {
          code: selected_user?.phone_code,
          phone: selected_user?.phone_number,
        },
        email: selected_user.email,
        role:
          selected_user.role.name === "COMPANY_SUPER_USER"
            ? selected_user.role.name
            : selected_user.role.name,
        business_unit: [
          ...selected_user.role.metafield.business_units.map(
            (elm: any) => elm.name
          ),
        ],
        region: [
          ...selected_user.role.metafield.regions.map((elm: any) => elm.name),
        ],
        country: [
          ...selected_user.role.metafield.countries.map((elm: any) => elm.name),
        ],
        grades: [
          ...selected_user.role.metafield.grades.map((elm: any) => elm.grade),
        ],
        job_function: [
          ...selected_user.role.metafield.job_functions.map(
            (elm: any) => elm.name
          ),
        ],
        remember: true,
      };
      form.setFieldsValue(values);
    }
  }, [
    form,
    selected_user,
    isLoadingBusinessUnits,
    isLoadingGrades,
    isLoadingJobFunctions,
  ]);

  const onSubmit = async (payload: any) => {
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber.phone,
        phone_code: phoneNumber.code,
        applications: ["TOM"],
        role: {
          name: role,
          description: role,
          metafield: {
            business_units: businessUnits
              .filter((elm: any) => businessUnitState.includes(elm.name))
              .map((elm: any) => elm.id),
            regions: regions
              .filter((elm: any) => regionState.includes(elm.name))
              ?.map((elm: any) => elm.id),
            countries: countries
              .filter((elm: any) => countryState.includes(elm.name))
              ?.map((elm: any) => elm.id),
            grades: grades
              .filter((elm: any) => grade.includes(elm.grade))
              ?.map((elm: any) => elm.id),
            job_functions: jobFunctions
              .filter((elm: any) => jobFunctionState.includes(elm.name))
              ?.map((elm: any) => elm.id),
          },
          permissions: [...permissionArray],
        },
        email: email,
      };
      console.log(data);
      if (sub_admin_id) {
        await updateCompanyUser({
          body: data,
          company_id,
          id: selected_user?.id,
        }).unwrap();
      } else {
        await createCompanyUser({ body: data, company_id }).unwrap();
      }
      subAdminForm.current.resetFields();
      message.success(
        `Sub Admin has been successfully ${
          sub_admin_id ? "updated" : "created"
        }.`
      );
      history.goBack();
    } catch (error) {
      console.log(error);
      // ErrorServices(error);
    }
  };

  const handleChange = (newValue: number[]) => {
    if (newValue?.length) {
      var tempRegions: string[];
      tempRegions = [];
      setBusinessUnitState(newValue);

      businessUnits
        .filter((item: any) => newValue.includes(item.name))
        .forEach((elm: any) => {
          tempRegions.push(...elm.regions);
        });

      var distinctArr: object[] = Object.values(
        tempRegions.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setRegionState([]);
      setCountryState([]);

      const values = {
        region: [],
        country: [],
        remember: true,
      };
      form.setFieldsValue(values);
      setRegions(distinctArr);
    } else {
      setCountries([]);
      setCountryState([]);
      setBusinessUnitState([]);
      setRegions([]);
      setRegionState([]);
      const values = {
        region: [],
        country: [],
        remember: true,
      };
      form.setFieldsValue(values);
    }
  };

  const handleRegionChange = (newValue: number[]) => {
    if (newValue?.length) {
      var tempCountries: string[];
      tempCountries = [];
      setRegionState(newValue);

      regions
        .filter((item: any) => newValue.includes(item.name))
        .forEach((elm: any) => {
          tempCountries.push(...elm.countries);
        });

      var distinctArr: object[] = Object.values(
        tempCountries.reduce((a: any, b: any) => {
          if (!a[b.id]) a[b.id] = b;
          return a;
        }, {})
      );
      setCountryState([]);

      const values = {
        country: [],
        remember: true,
      };
      form.setFieldsValue(values);
      setCountries(distinctArr);
    } else {
      setCountries([]);
      setCountryState([]);
      setRegionState([]);
      const values = {
        country: [],
        remember: true,
      };
      form.setFieldsValue(values);
    }
  };

  return (
    <>
      <h1 className="form_heading">{`${
        sub_admin_id ? "Update" : "Create"
      } Sub-Admin`}</h1>
      <ConfigProvider locale={en}>
        <Form
          name="sub_admin"
          form={form}
          ref={subAdminForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          layout="vertical"
          className="create__company__container"
        >
          <div className="sub__admin_details_container">
            <h1 className="section__heading">Basic information</h1>
            <div className="form__section">
              <div className="form__section_container">
                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={<label className="input__label">First name</label>}
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      type="text"
                      placeholder="Enter first name here..."
                      name="first_name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={<label className="input__label">Last name</label>}
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      name="last_name"
                      type="text"
                      placeholder="Enter last name here..."
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Contact number</label>
                    }
                    name="phoneNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please enter contact number",
                      },
                    ]}
                  >
                    <ConfigProvider locale={en}>
                      <CountryPhoneInput
                        name="phoneNumber"
                        value={{
                          phone: phoneNumber?.phone,
                          code: phoneNumber?.code || 92,
                        }}
                        onChange={v => {
                          setPhoneNumber(v);
                        }}
                      />
                    </ConfigProvider>
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Email address</label>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email address",
                      },
                    ]}
                  >
                    <Input
                      className="form__input"
                      name="email"
                      type="text"
                      placeholder="Enter email address here..."
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={<label className="input__label">Role</label>}
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please select role",
                      },
                    ]}
                  >
                    <div>
                      {!isCustomRole ? (
                        <Select
                          size="large"
                          className="form__select"
                          placeholder="Select role from here..."
                          showArrow
                          showSearch={false}
                          value={role}
                          onChange={value => setRole(value)}
                        >
                          <Option
                            key={"COMPANY_SUPER_USER"}
                            value={"COMPANY_SUPER_USER"}
                          >
                            Company Super User
                          </Option>
                        </Select>
                      ) : (
                        <Input
                          className="form__input"
                          name="role"
                          type="text"
                          placeholder="Enter custom role here..."
                          value={role}
                          onChange={e => setRole(e.target.value)}
                        />
                      )}
                      {/* {!isCustomRole ? (
                        <div
                          className="change_role"
                          onClick={() => {
                            setIsCustomRole(true);
                            setRole("");
                          }}
                        >
                          Create custom role
                        </div>
                      ) : (
                        <div
                          className="change_role"
                          onClick={() => {
                            setIsCustomRole(false);
                            setRole("");
                          }}
                        >
                          Select from default roles
                        </div>
                      )} */}
                    </div>
                  </Form.Item>

                  <div className="form__item contact__person_item" />
                </div>
              </div>
            </div>
          </div>

          <div className="sub__admin_details_container">
            <h1 className="section__heading margin__bottom__0">
              Define Extended Permissions
            </h1>
            <div className="form__section">
              <div className="form__section_container">
                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">
                        Choose Business Units
                      </label>
                    }
                    name="business_unit"
                    rules={[
                      {
                        required: role === "COMPANY_SUPER_USER" ? false : true,
                        message: "Please select a business units",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={role === "COMPANY_SUPER_USER" ? true : false}
                      size="large"
                      className="form__select"
                      placeholder="Search and select business units"
                      loading={isLoadingBusinessUnits}
                      showArrow
                      showSearch={false}
                      value={businessUnitState}
                      style={{ width: "100%" }}
                      onChange={handleChange}
                    >
                      {businessUnits?.map(
                        (businessUnit: Partial<IBusinessUnitItem>) => (
                          <Option
                            key={businessUnit?.id}
                            value={businessUnit.name!}
                          >
                            {businessUnit?.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Choose a Region</label>
                    }
                    name="region"
                    rules={[
                      {
                        required: role === "COMPANY_SUPER_USER" ? false : true,
                        message: "Please select a region",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={
                        (role === "COMPANY_SUPER_USER" ? true : false) ||
                        !businessUnitState?.length
                      }
                      size="large"
                      className="form__select"
                      placeholder="Search and select regions"
                      showArrow
                      showSearch={false}
                      value={regionState}
                      onChange={handleRegionChange}
                    >
                      {regions?.map((region: Partial<IRegion>) => (
                        <Option key={region?.id} value={region.name!}>
                          {region?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Choose a Country</label>
                    }
                    name="country"
                    rules={[
                      {
                        required: role === "COMPANY_SUPER_USER" ? false : true,
                        message: "Please select a Country",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={
                        (role === "COMPANY_SUPER_USER" ? true : false) ||
                        !regionState?.length
                      }
                      size="large"
                      className="form__select"
                      placeholder="Search and select Country"
                      showArrow
                      showSearch={false}
                      value={countryState}
                      onChange={newValue => {
                        console.log(newValue);
                        setCountryState(newValue);
                      }}
                    >
                      {countries?.map((country: ICountry) => (
                        <Option key={country?.id} value={country.name}>
                          {country?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Choose Grades</label>
                    }
                    name="grades"
                    rules={[
                      {
                        required: role === "COMPANY_SUPER_USER" ? false : true,
                        message: "Please select a grade",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={role === "COMPANY_SUPER_USER" ? true : false}
                      size="large"
                      className="form__select"
                      placeholder="Search and select grades"
                      loading={isLoadingGrades}
                      showArrow
                      showSearch={false}
                      value={grade}
                      onChange={newValue => {
                        setGrade(newValue);
                      }}
                    >
                      {grades?.map((grade: Partial<IJobGrade>) => (
                        <Option key={grade?.id!} value={grade.grade!}>
                          {grade?.grade}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="contact__person__sub_container">
                  <Form.Item
                    className="form__item contact__person_item"
                    label={
                      <label className="input__label">Job Functions</label>
                    }
                    name="job_function"
                    rules={[
                      {
                        required: role === "COMPANY_SUPER_USER" ? false : true,
                        message: "Please select a job function",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={role === "COMPANY_SUPER_USER" ? true : false}
                      loading={isLoadingJobFunctions}
                      size="large"
                      className="form__select"
                      placeholder="Search and select job functions"
                      showArrow
                      showSearch={false}
                      onChange={newValue => {
                        setJobFunctionState(newValue);
                      }}
                      value={jobFunctionState}
                    >
                      {jobFunctions?.map(
                        (jobFunction: Partial<IJobFunctionItem>) => (
                          <Option
                            key={jobFunction?.id}
                            value={jobFunction?.name!}
                          >
                            {jobFunction?.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>

                  <div className="form__item contact__person_item" />
                </div>
              </div>
            </div>
          </div>

          <div className="sub__admin_details_container">
            <h1 className="section__heading margin__bottom__0">
              Permission Settings
            </h1>
            <div className="form__section">
              <div className="form__section_container">
                <PermissionsTable
                  role={role === "COMPANY_SUPER_USER" ? true : false}
                  setCallback={(val: any) => setPermissionArray(val)}
                  permissionAPIArray={permissionAPIArray}
                />
              </div>
            </div>
          </div>

          <div className="form__submit__section">
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={false}
                size="large"
                loading={isLoading || isUpdating}
              >
                {`${sub_admin_id ? "Update" : "Create"} Sub-Admin`}
              </Button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                className="login__btn"
                size="large"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default CreateSubAdmin;
