import { useState } from "react";
import { Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./jobGradeDefination.less";
import Table from "@components/Table";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import AddJobGradeDefination from "./AddJobGradeDefination";
import {
  useFetchAllGradePointsForCompaniesQuery,
  useFetchAllJobGradeMappingCompaniesQuery,
} from "@/services/jobGradeMapping";

const JobGradeDefination = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [page, setPage] = useState(1);

  const { data: JobGradeMappingCompaniesData } =
    useFetchAllJobGradeMappingCompaniesQuery(null);
  const { data } = JobGradeMappingCompaniesData || { data: [] };

  const {
    data: GradePointsForCompaniesData,
    isLoading: isLoadingGradePointsForCompanies,
  } = useFetchAllGradePointsForCompaniesQuery(null);
  const { data: gradePointsForCompany, pagination } =
    GradePointsForCompaniesData || { data: [] };

  const columns = [
    {
      title: "GRADE POINTS",
      key: "display_name",
      width: 200,
      render: (record: any) => (
        <div className="text-wrap">{record.grade_point_range.display_name}</div>
      ),
    },
    ...data.map((eachCompany: { id: string; name: string }) => {
      return {
        title: eachCompany.name,
        key: eachCompany["id"].toString(),
        width: 200,
        render: (record: any) => {
          return <div className="text-wrap">{record[eachCompany["id"]]}</div>;
        },
      };
    }),
  ];

  const onCloseModal = () => {
    setIsVisible(false);
  };

  return (
    <div className="companies">
      <AddJobGradeDefination
        isVisible={isVisible}
        setIsVisible={onCloseModal}
      />
      <Row>
        <Col span={24}>
          <div className="main-heading mb-16">Job Grade Definitions</div>
          <div className="companies__upper mb-20">
            <Button
              onClick={() => {
                dispatch(resetJobDetails);
                setIsVisible(true);
              }}
              type="primary"
            >
              <PlusOutlined /> Create Job Definitions
            </Button>
          </div>
          <Table
            columns={columns}
            data={gradePointsForCompany}
            isLoading={isLoadingGradePointsForCompanies}
            pagination={true}
            count={pagination?.count}
            onChangePage={setPage}
            page={page}
          />
        </Col>
      </Row>
    </div>
  );
};

export default JobGradeDefination;
