export { default as adminVars } from "./admin.json";
export { default as clientVars } from "./client.json";
export * from "./roles";

export const dateFormat = "YYYY-MM-DD h:mm:ss a";
export const cookiesDomain = process.env.REACT_APP_COOKIES_DOMAIN;
export const ssoUrl = process.env.REACT_APP_SSO_URL || "";
export const appsPageUrl = process.env.REACT_APP_APPS_PAGE_URL;

export enum JobEvaluationDropDownKeys {
  KNOWLEDGE_AND_SKILLS = "ks",
  KNOWLEDGE_SKILLS_APPLICATION = "ksa",
  PROBLEM_SOLVING_SOLUTIONING = "pss",
  NATURE_OF_COMMUNICATION = "nc",
  COMPLEXITY = "complex",
  ROLE_IMPACT_ON_ORGANIZATION = "rioo",
  IMPACT_OF_DECISION_MAKING = "idm",
  NON_FINANCIAL_RESPONSIBILITY = "nfr",
  REVENUE_RESPONSIBILITY = "rr",
}

export enum EvaluationModelKeys {
  Financial = "financial",
  NON_FINANCIAL = "non_financial",
}

export const evaluationModelOptions = [
  { key: EvaluationModelKeys.Financial, display_name: "Financial" },
  { key: EvaluationModelKeys.NON_FINANCIAL, display_name: "Non-Financial" },
];

export enum ApiMethodes {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

export enum JobEvaluationInquiryStatus {
  CLOSE = "close",
  OPEN = "open",
}

export const endpoints = {
  JOB_EVALUATION: {
    LIST: () => `/blocks`,
    DETAIL: (id: string) => `/details/${id}`,
    JOB_EVALUATION_DROPDOWNS: (
      jobEvaluationDropDownKey: JobEvaluationDropDownKeys
    ) => `/job-evaluations/enums/?enum=${jobEvaluationDropDownKey}`,
    CREATE_JOB_FOR_EVALUATION: (company_id: number) =>
      `/company/${company_id}/job-evaluations/`,
    UPDATE_JOB_EVALUATION: (company_id: number, job_id: number) =>
      `/company/${company_id}/job-evaluations/${job_id}/`,
    GET_JOB_EVALUATION_BY_ID: (
      company_id: number | string,
      job_id: number | string
    ) => `/company/${company_id}/job-evaluations/${job_id}/`,
    GET_ALL_JOB_EVALUATIONS: (company_id: number, page: number) =>
      `/company/${company_id}/job-evaluations/?page=${page}`,
    CREATE_JOB_EVALUATION: (company_id: number, job_id: number) =>
      `/company/${company_id}/job-evaluations/${job_id}/evaluate/`,
    UPLOAD_JOB_EVALUATION_DOCUMENTS: (
      company_id: number | string,
      job_id: number | string
    ) => `/company/${company_id}/job-evaluations/${job_id}/documents/`,
  },
  JOB_GRADE_MAPPING: {
    LIST: () => `/job-grade-mapping/grade-points/`,
    COMPANIES_DD: () => `/job-grade-mapping/company/all`,
    GRADE_POINT_RANGES_DD: (company_id: string) =>
      `/job-grade-mapping/grade-point-ranges/?company_id=${company_id}`,
    CREATE: () => `/job-grade-mapping/`,
  },
} as const;

export const validationsRules = {
  requiredWithDefaultMessage: {
    required: true,
    message: "This field is required",
  },
};
