import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, message } from "antd";

import Modal from "@components/Modal";
import { IModal } from "@types";
import { ErrorServices } from "@services";
import { useTypedSelector } from "@/hooks";
import { KeyAndDisplayName } from "@/store/jobEvaluation";
import {
  ICreateJobGradeMapping,
  useCreateJobGradeMappingMutation,
  useFetchAllJobGradeMappingCompaniesQuery,
  useFetchGradePointRangesByCompanyIdQuery,
} from "@/services/jobGradeMapping";

const { Option } = Select;

export type dropdown = string | undefined;

export interface IAddJob extends IModal {
  isVisible: boolean;
  setIsVisible: () => void;
}

interface IFormValues {
  company_id?: string;
  grade_point_range: KeyAndDisplayName;
  grade_point_value?: string;
}

const INITIAL_VALUES: IFormValues = {
  company_id: "",
  grade_point_range: null,
  grade_point_value: "",
};

export interface IError {
  success?: boolean;
  message?: string;
  error?: any;
  code?: number;
  data?: any;
  is_validation_error?: boolean;
}

const AddJob: React.FC<IAddJob> = ({ isVisible, setIsVisible }) => {
  const [jobGradeDefinationForm, setjobGradeDefinationForm] =
    useState(INITIAL_VALUES);

  const [disableGredeRangeDropDown, setdisableGredeRangeDropDown] =
    useState(false);

  const onChange = (event: any) => {
    setjobGradeDefinationForm(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const hasCompanyIdSected = jobGradeDefinationForm.company_id ? true : false;
  const {
    data: JobGradeMappingCompaniesData,
    isLoading: isLoadingJobGradeMappingCompaniesData,
  } = useFetchAllJobGradeMappingCompaniesQuery(null);
  const { data: jobGradeMappingCompanies } = JobGradeMappingCompaniesData || {
    data: [],
  };

  const {
    data: JobGradePointRangesData,
    isLoading: isLoadingGradePointRangesData,
    isError,
    error,
  } = useFetchGradePointRangesByCompanyIdQuery(
    {
      company_id: jobGradeDefinationForm.company_id!,
    },
    {
      skip: !hasCompanyIdSected,
    }
  );
  const { data: jobGradePointRanges } = JobGradePointRangesData || {
    data: [],
  };

  console.log(`JobGradePointRangesData - ${error} , isError : ${isError} `);
  console.log({ error });

  function getErrorDetails(error: any): error is IError {
    return "message" in error;
  }

  useEffect(() => {
    if (isError) {
      if (error && getErrorDetails(error)) {
        !error.is_validation_error && message.error(error.message);
        setdisableGredeRangeDropDown(true);
        setjobGradeDefinationForm({
          ...jobGradeDefinationForm,
          grade_point_range: null,
        });
      }
    } else {
      setdisableGredeRangeDropDown(false);
    }
  }, [error]);

  const { jobDetails } = useTypedSelector(state => state?.jobEvaluation);

  const disabled =
    !jobGradeDefinationForm.company_id ||
    !jobGradeDefinationForm.grade_point_range?.key ||
    !jobGradeDefinationForm.grade_point_value;

  const [createJobGradeMapping, { isLoading }] =
    useCreateJobGradeMappingMutation();

  const onSubmit = async () => {
    try {
      const body: ICreateJobGradeMapping = {
        grade_point_range: jobGradeDefinationForm.grade_point_range?.key!,
        grade_point_value: jobGradeDefinationForm.grade_point_value!,
        company: jobGradeDefinationForm.company_id!,
      };
      await createJobGradeMapping({
        body,
      }).unwrap();

      message.success("Job Grade Mapping Created Successfully");
      setjobGradeDefinationForm({
        ...jobGradeDefinationForm,
        grade_point_range: null,
        grade_point_value: "",
      });
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const {
    departmentName,
    country_id,
    evaluation_model,
    noOfDirectReports,
    jobTitle,
    job_function,
    sub_job_function,
  } = jobDetails;

  return (
    <Modal
      footer={[
        <Button disabled={disabled} key="1" type="primary" onClick={onSubmit}>
          {`Add Company Grade Points Mapping`}
        </Button>,
        <Button
          key="2"
          onClick={() => {
            setIsVisible();
          }}
        >
          Cancel
        </Button>,
      ]}
      title={`Define Job Grade Mapping`}
      isVisible={isVisible}
    >
      <>
        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label>Company</label>
            <Select
              loading={isLoadingJobGradeMappingCompaniesData}
              onChange={company_id => {
                setjobGradeDefinationForm(prev => ({
                  ...prev,
                  company_id,
                }));
              }}
              value={jobGradeDefinationForm.company_id || undefined}
              showArrow={true}
              size="large"
              showSearch={true}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select country from here..."
            >
              {jobGradeMappingCompanies?.map(
                (eachCompany: { id: string; name: string }) => (
                  <Option key={eachCompany.id} value={eachCompany.id}>
                    {eachCompany.name}
                  </Option>
                )
              )}
            </Select>
          </Col>
        </Row>
        <Row className="modal__row" justify="space-between">
          <Col span={11}>
            <label>Grade Point Range</label>
            <Select
              loading={isLoadingGradePointRangesData}
              disabled={
                jobGradeDefinationForm.company_id && !disableGredeRangeDropDown
                  ? false
                  : true
              }
              onChange={(serialized_gradePointRanges: string) => {
                const gradePointRangesData: KeyAndDisplayName = JSON.parse(
                  serialized_gradePointRanges
                );
                setjobGradeDefinationForm(prev => ({
                  ...prev,
                  grade_point_range: {
                    key: gradePointRangesData?.key.toString()!,
                    display_name:
                      gradePointRangesData?.display_name.toString()!,
                  },
                }));
              }}
              value={
                jobGradeDefinationForm.grade_point_range?.display_name ||
                undefined
              }
              showArrow={true}
              size="large"
              showSearch={true}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select factors from here..."
            >
              {jobGradePointRanges.map((option: KeyAndDisplayName) => (
                <Option key={option?.key} value={JSON.stringify(option)}>
                  {option?.display_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label>Grade Point Value</label>
            <Input
              placeholder="Enter Grade Point Value here..."
              size="large"
              name={"grade_point_value"}
              onChange={onChange}
              value={jobGradeDefinationForm.grade_point_value || ""}
            />
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default AddJob;
