import { FC } from "react";
import { Redirect, Route } from "react-router-dom";

import { paths } from ".";
import { IRoute } from "./types";
import useAppRoleGuard from "@/hooks/useAppRoleGuard";
import { ssoUrl } from "@/constants";

const RouteWithSubRoutes: FC<IRoute> = route => {
  const { isAuthenticated, isAuthorized } = useAppRoleGuard(route);
  if (route.isPrivate && (!isAuthenticated || !isAuthorized)) {
    window.open(ssoUrl, "_self");
  }

  if (!route?.isPrivate) {
    return (
      <Route
        path={route.path}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
  }
  if (isAuthenticated && isAuthorized && route?.isPrivate) {
    return (
      <Route
        path={route.path}
        render={props => <route.component routes={route.routes} />}
      />
    );
  } else {
    return <Route path={route.path} render={props => <></>} />;
  }
};

export default RouteWithSubRoutes;
