import { UserRoles } from "@/constants";
import { Dispatch, SetStateAction } from "react";

type NullOrString = null | string;
type NullOrNumber = null | number;

/**
 * @Modal_Types
 */

export interface IModal {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * @Auth_Types
 */

export interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_code: number;
  role: UserRoles;
  is_active: boolean;
  company?: ICompany;
}

export type User = IUser | null;

export interface IAuthState {
  user: User;
  token: {
    access: NullOrString;
    refresh: NullOrString;
  };
  permissions: string[];
  is_one_time_password: boolean;
  applications: [];
}

/**
 * @Business_Unit_Types
 */

export interface IBusinessUnitState {
  list: Array<IBusinessUnitItem>;
}

export interface IBusinessUnitItem {
  id: number;
  name: string;
  sector: {
    id: number;
    name: string;
  };
  industry: {
    id: number;
    name: string;
  };
  sub_industry: {
    id: number;
    name: string;
  };
  region_count: number;
}

/**
 * @Company_Types
 */

export interface ICompany {
  id?: number;
  name?: string;
  address?: string;
  postal_code?: string;
  country_headquarter?: string;
  financial_year?: number;
  contract_start_date?: string;
  contract_end_date?: string;
  large_logo_url?: string;
  small_logo_url?: string;
  status?: string;
  is_active?: boolean;
  country: ICountry;
  currency: ICurrency;
  person_country: ICountry;
  stock_tracking_ids: string[];
  user?: User;
}

export interface ICompaniesState {
  companies: ICompany[];
}

export interface IRegion {
  id?: number;
  name: string;
  countries: ICountry[] | number[];
  business_units: any;
}

export interface ILegalEntity {
  id: number;
  name: string;
}

/**
 * @Country_Types
 */

export interface ICountry {
  id: number;
  name: string;
}

/**
 * @Grade_Types
 */

export interface IGradeCompanyRank {
  rank: NullOrString;
  ta_rank_id: number;
  ta_rank: string;
}

export interface IGradeCompany {
  company: NullOrString;
  id: number;
  name: string;
  grade_company_ranks: IGradeCompanyRank[];
}

export interface ITARank {
  id: number;
  rank: NullOrString;
}

export interface IGradeState {
  taRanks: ITARank[];
  allGradeCompanies: IGradeCompany[];
}

/**
 * @Job_Function_Types
 */

export interface IJobFunctionItem {
  id: number;
  name: string;
  description: string;
  job_sub_functions: { [key: string]: any }[];
}

export interface IJobEvaluationEnumBasedDDItem {
  key: string;
  display_name: string;
}

/**
 * @Sectors_Types
 */

export interface ISector {
  id: number;
  name: string;
  description: NullOrString;
  industries: IIndustry[];
}

export interface ISectorsState {
  allSectors: [];
  sectors: ISector[];
}

export interface IIndustry {
  id: number;
  name: string;
  description: NullOrString;
  sub_industries?: ISubIndustry[];
}

export interface ISubIndustry {
  id?: number;
  name: string;
}

/**
 * @SubAdmin_Types
 */

export interface ISubAdminReducer {
  list: Array<ISubAdminItem>;
  pagination: any;
  subAdmin: { [key: string]: any };
}

export interface ISubAdminItem {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  phone_code: number;
  role: string;
  email: string;
  is_active: boolean;
}

/**
 * @Sub_Job_Function_Types
 */

export interface ISubJobFunctionItem {
  id: number;
  name: string;
}

/**
 * @Company_Job_Grades_Types
 */

export interface IJobGrade {
  grade: string;
  type: NullOrString;
  country_ids?: number[];
  countries?: ICountry[];
  id?: number;
  is_global?: boolean;
}

/**
 * @Company_Salary_Range_Types
 */
export interface ISalaryRange {
  id?: number;
  grade: string;
  tier: NullOrNumber;
  year: NullOrNumber;
  country_id?: NullOrNumber;
  country?: ICountry | undefined;
  city: string;
  range_type: string;
  salary_min: NullOrNumber;
  salary_mid: NullOrNumber;
  salary_max: NullOrNumber;
}

/**
 * @Cash_Allowances_Types
 */

export interface ICashAllowance {
  allowance_name: "" | null;
  id?: number;
  grade: NullOrString;
  country_id?: NullOrNumber;
  country?: ICountry | undefined;
  city: NullOrString;
  is_all_grade: boolean;
  is_percentage: boolean;
  is_basic_pay: boolean;
  value: NullOrNumber;
}

/**
 * @Short_Term_Plan_Types
 */

export interface IPlanType {
  id: number;
  name: string;
}

export interface IShortTermPlan {
  id: number;
  name: string;
  is_global: boolean;
  is_all_grade: boolean;
  countries: ICountry[];
  grades: string[];
  type: IPlanType;
}

/**
 * @Short_Term_Incentive_Types
 */

export interface IShortTermIncentive {
  id: number;
  grade: string;
  country: ICountry;
  is_all_grade: boolean;
  is_global: boolean;
  is_percentage: boolean;
  is_basic_pay: boolean;
  value: number;
  plan: Omit<IShortTermPlan, "grades" | "countries">;
}

/**
 * @Long_Term_Plan_Types
 */

export interface ILongTermPlan {
  id: number;
  name: string;
  is_global: boolean;
  is_all_grade: boolean;
  grades: string[];
  countries: ICountry[];
  type: IPlanType;
  currency: number;
  holding_period: number;
  is_installment: boolean;
  is_amount: boolean;
  is_adhoc: boolean;
  date_of_grant: number;
  month_of_grant: number;
  installment_type: string;
}

/**
 * @Long_Term_Incentive_Types
 */

export interface ILongTermIncentive {
  id: number;
  grade: NullOrString;
  country: ICountry | null;
  year: number;
  range_type: string;
  is_all_grade: boolean;
  is_global: boolean;
  is_amount: boolean;
  equity_min: number;
  equity_mid: number;
  equity_max: number;
  plan: ILongTermPlan;
}

/**
 * @Currency_Type
 */

export interface ICurrency {
  id: number;
  name: string;
  symbol: string;
  code: string;
}

/**
 * @Version_Type
 */

export interface IVersion {
  created_at: string;
  id: number;
  is_active: NullOrString;
}

/**
 * @Offer_Type
 */

export type Stage = "CANDIDATE_DETAILS" | "POSITION_DETAILS" | "OFFER_MODELLER";

export interface IPositionDetails {
  id: number;
  grade: string;
  reporting_grade: NullOrString;
  job_title: NullOrString;
  position_owner: NullOrString;
  city: NullOrString;
  grade_type: NullOrString;
  reporting_title: NullOrString;
  cost_centre: NullOrString;
  is_critical: true;
  position_comment: NullOrString;
  country: ICountry;
  business_unit: Partial<IBusinessUnitItem>;
  region: Partial<IRegion>;
  job_function: Partial<IJobFunctionItem>;
  sub_job_function: ISubJobFunctionItem;
  created_at: string;
  currency: ICurrency;
}

export interface ICandidateDetails {
  id: number;
  candidate_name: string;
  company_name: string;
  current_title: string;
  current_grade: NullOrString;
  business_unit: NullOrString;
  region: NullOrString;
  location: string;
  relevant_experience: number;
  total_experience: number;
  is_critical_skills: boolean;
  critical_skills: NullOrString;
  gender: NullOrString;
  is_referral: boolean;
  referral_employees: NullOrString;
  country: ICountry;
  currency: ICurrency;
  current_job_function: Partial<IJobFunctionItem>;
  current_sub_job_function: null | Partial<ISubJobFunctionItem>;
  sector: Partial<ISector> | null;
  industry: null | Partial<IIndustry>;
  sub_industry: null | Partial<ISubIndustry>;
  created_at: string;
}

export interface IOffer {
  id: number;
  status: "DRAFTED" | "PLACED" | "ACCEPTED" | "REJECTED";
  stage: "POSITION_DETAILS" | "CANDIDATE_DETAILS" | "OFFER_MODELLER";
  is_active: true;
  created_by: Partial<IUser>;
  company: Partial<ICompany>;
  offer_position_detail: IPositionDetails | null;
  offer_candidate_detail: ICandidateDetails | null;
  offer_fixed_cash: null;
  created_at: string;
}

export interface OfferModellerAddedField {
  name: NullOrString;
  value: NullOrNumber;
  is_current: boolean;
  id?: number;
  auto_populate?: boolean;
  is_percentage?: boolean;
  percentage?: null | number;
  unit?: boolean;
  readonly?: boolean;
}

// Job Evaluation Steps
export const EVALUATION_INFORMATION_STEP = "EVALUATION_INFORMATION_STEP";
export const JOB_DETAILS_STEP = "JOB_DETAILS_STEP";
export const KNOWLEDGE_SKILL_APPLICATION_STEP =
  "KNOWLEDGE_SKILL_APPLICATION_STEP";

export enum JobEvaluationStages {
  EVALUATION_INFORMATION_STEP = "EVALUATION_INFORMATION_STEP",
  JOB_DETAILS_STEP = "JOB_DETAILS_STEP",
  KNOWLEDGE_SKILL_APPLICATION_STEP = "KNOWLEDGE_SKILL_APPLICATION_STEP",
  PROBLEM_SOLVING_SOLUTIONING = "PROBLEM_SOLVING_SOLUTIONING",
  STAKE_HOLDER_MANAGEMENT = "STAKE_HOLDER_MANAGEMENT",
  DECISION_MAKING_IMPACT = "DECISION_MAKING_IMPACT",
  FINANCIAL_RESPONSIBILITY = "FINANCIAL_RESPONSIBILITY",
}

export interface IJobEvaluationStages {
  id: number;
  status: "DRAFTED" | "PLACED" | "ACCEPTED" | "REJECTED";
  stage: JobEvaluationStages;
  is_active: true;
}

export interface PermissionsTableModal {
  permissionAPIArray: Array<string>;
  role: boolean;
  setCallback: Dispatch<SetStateAction<any>>;
}
