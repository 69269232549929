import { useEffect, useState, FC } from "react";
import { Switch, Checkbox } from "antd";
import { PermissionsTableModal } from "@types";
import "./style.less";

const PERMS = {
  businessUnits: "BUSINESS_UNIT",
  region: "REGION",
  grade: "JOB_GRADE",
  // salaryRange: "SALARY_RANGE",
  allowances: "CASH_ALLOWANCE",
  payrollUpload: "INTERNAL_PAYROLL",
  marketDataUpload: "MARKET_DATA",
  offerCreation: "OFFER",
  shortTermIncentives: "COMPANY_STI",
  country: "COUNTRY",
};

const CREATE = {
  createBusinessUnits: "CREATE_BUSINESS_UNIT",
  createRegion: "CREATE_REGION",
  createGrade: "CREATE_JOB_GRADE",
  createSalaryRange: "CREATE_SALARY_RANGE",
  createAllowances: "CREATE_CASH_ALLOWANCE",
  createPayrollUpload: "CREATE_INTERNAL_PAYROLL",
  createMarketDataUpload: "CREATE_MARKET_DATA",
  createOfferCreation: "CREATE_OFFER",
  createShortTermIncentives: "CREATE_COMPANY_STI",
  createCountry: "CREATE_COUNTRY",
};

const VIEW = {
  viewBusinessUnits: "VIEW_BUSINESS_UNIT",
  viewRegion: "VIEW_REGION",
  viewGrade: "VIEW_JOB_GRADE",
  viewSalaryRange: "VIEW_SALARY_RANGE",
  viewAllowances: "VIEW_CASH_ALLOWANCE",
  viewPayrollUpload: "VIEW_INTERNAL_PAYROLL",
  viewMarketDataUpload: "VIEW_MARKET_DATA",
  viewOfferCreation: "VIEW_OFFER",
  viewShortTermIncentives: "VIEW_COMPANY_STI",
  viewCountry: "VIEW_COUNTRY",
};

const UPDATE = {
  updateBusinessUnits: "UPDATE_BUSINESS_UNIT",
  updateRegion: "UPDATE_REGION",
  updateGrade: "UPDATE_JOB_GRADE",
  updateSalaryRange: "UPDATE_SALARY_RANGE",
  updateAllowances: "UPDATE_CASH_ALLOWANCE",
  updatePayrollUpload: "UPDATE_INTERNAL_PAYROLL",
  updateMarketDataUpload: "UPDATE_MARKET_DATA",
  updateOfferCreation: "UPDATE_OFFER",
  updateShortTermIncentives: "UPDATE_COMPANY_STI",
  updateCountry: "UPDATE_COUNTRY",
};

const DELETE = {
  deleteBusinessUnits: "DELETE_BUSINESS_UNIT",
  deleteRegion: "DELETE_REGION",
  deleteGrade: "DELETE_JOB_GRADE",
  deleteSalaryRange: "DELETE_SALARY_RANGE",
  deleteAllowances: "DELETE_CASH_ALLOWANCE",
  deletePayrollUpload: "DELETE_INTERNAL_PAYROLL",
  deleteMarketDataUpload: "DELETE_MARKET_DATA",
  deleteOfferCreation: "DELETE_OFFER",
  deleteShortTermIncentives: "DELETE_COMPANY_STI",
  deleteCountry: "DELETE_COUNTRY",
};

const PermissionsTable: FC<PermissionsTableModal> = ({
  permissionAPIArray,
  role,
  setCallback,
}) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (permissionAPIArray?.length > 0) {
      setPermissions(permissionAPIArray);
    }
  }, [permissionAPIArray]);

  const handleChange = (e: any, perm: string) => {
    console.log(e, perm);
    const tempPermissions = [...permissions];
    if (e.target.checked) {
      tempPermissions.push(perm);
      setPermissions(tempPermissions);
    } else {
      tempPermissions.splice(tempPermissions.indexOf(perm), 1);
      setPermissions(tempPermissions);
    }
  };

  const handleSwitchChange = (e: any, perm: string, val: string) => {
    console.log(e, perm);
    const tempPermissions = [...permissions];
    if (e) {
      tempPermissions.push(perm);
      setPermissions(tempPermissions);
    } else {
      setPermissions(tempPermissions.filter(elm => !elm.includes(val)));
    }
  };

  useEffect(() => {
    console.log(permissions);
    setCallback(permissions);
  }, [permissions]);

  return (
    <table className="per_table">
      <thead>
        <tr>
          <th className="per_table_th">All Permisions</th>
          <th className="per_table_th"></th>
          <th className="per_table_th">Create</th>
          <th className="per_table_th">View</th>
          <th className="per_table_th">Update</th>
          <th className="per_table_th">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="per_table_td">
            <div>Business Units</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewBusinessUnits,
                  PERMS.businessUnits
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, CREATE.createBusinessUnits);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewBusinessUnits,
                  PERMS.businessUnits
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, UPDATE.updateBusinessUnits);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, DELETE.deleteBusinessUnits);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Region</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewRegion, PERMS.region);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, CREATE.createRegion);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewRegion,
                  PERMS.region
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, UPDATE.updateRegion);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, DELETE.deleteRegion);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Country</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewCountry, PERMS.country);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, CREATE.createCountry);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewCountry,
                  PERMS.country
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, UPDATE.updateCountry);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              // disabled={role || !permissions.includes(VIEW.viewCountry)}
              // checked={role || permissions.includes(DELETE.deleteCountry)}
              disabled={true}
              checked={true}
              onChange={e => {
                handleChange(e, DELETE.deleteCountry);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Job Evaluation</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch disabled={true} checked={true} />
          </td>
          <td className="per_table_td">
            <Checkbox disabled={true} checked={true} />
          </td>
          <td className="per_table_td">
            <Checkbox disabled={true} checked={true} />
          </td>
          <td className="per_table_td">
            <Checkbox disabled={true} checked={true} />
          </td>
          <td className="per_table_td"></td>
        </tr>
        {/* <tr>
          <td className="per_table_td">
            <div>Grades</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewGrade)}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewGrade, PERMS.grade);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewGrade)}
              checked={role || permissions.includes(CREATE.createGrade)}
              onChange={e => {
                handleChange(e, CREATE.createGrade);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewGrade)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewGrade,
                  PERMS.grade
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewGrade)}
              checked={role || permissions.includes(UPDATE.updateGrade)}
              onChange={e => {
                handleChange(e, UPDATE.updateGrade);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewGrade)}
              checked={role || permissions.includes(DELETE.deleteGrade)}
              onChange={e => {
                handleChange(e, DELETE.deleteGrade);
              }}
            />
          </td>
        </tr> */}
        {/* <tr>
          <td className="per_table_td">
            <div>Salary Range</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewSalaryRange)}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewSalaryRange, PERMS.salaryRange);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewSalaryRange)}
              checked={role || permissions.includes(CREATE.createSalaryRange)}
              onChange={e => {
                handleChange(e, CREATE.createSalaryRange);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewSalaryRange)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewSalaryRange,
                  PERMS.salaryRange
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewSalaryRange)}
              checked={role || permissions.includes(UPDATE.updateSalaryRange)}
              onChange={e => {
                handleChange(e, UPDATE.updateSalaryRange);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewSalaryRange)}
              checked={role || permissions.includes(DELETE.deleteSalaryRange)}
              onChange={e => {
                handleChange(e, DELETE.deleteSalaryRange);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Allowances</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewAllowances)}
              onChange={e => {
                handleSwitchChange(e, VIEW.viewAllowances, PERMS.allowances);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewAllowances)}
              checked={role || permissions.includes(CREATE.createAllowances)}
              onChange={e => {
                handleChange(e, CREATE.createAllowances);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewAllowances)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewAllowances,
                  PERMS.allowances
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewAllowances)}
              checked={role || permissions.includes(UPDATE.updateAllowances)}
              onChange={e => {
                handleChange(e, UPDATE.updateAllowances);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewAllowances)}
              checked={role || permissions.includes(DELETE.deleteAllowances)}
              onChange={e => {
                handleChange(e, DELETE.deleteAllowances);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Short Term Incentives</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={
                role || permissions.includes(VIEW.viewShortTermIncentives)
              }
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewShortTermIncentives,
                  PERMS.shortTermIncentives
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewShortTermIncentives)
              }
              checked={
                role || permissions.includes(CREATE.createShortTermIncentives)
              }
              onChange={e => {
                handleChange(e, CREATE.createShortTermIncentives);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={
                role || permissions.includes(VIEW.viewShortTermIncentives)
              }
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewShortTermIncentives,
                  PERMS.shortTermIncentives
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewShortTermIncentives)
              }
              checked={
                role || permissions.includes(UPDATE.updateShortTermIncentives)
              }
              onChange={e => {
                handleChange(e, UPDATE.updateShortTermIncentives);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewShortTermIncentives)
              }
              checked={
                role || permissions.includes(DELETE.deleteShortTermIncentives)
              }
              onChange={e => {
                handleChange(e, DELETE.deleteShortTermIncentives);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Payroll Upload</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewPayrollUpload)}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewPayrollUpload,
                  PERMS.payrollUpload
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewPayrollUpload)}
              checked={role || permissions.includes(CREATE.createPayrollUpload)}
              onChange={e => {
                handleChange(e, CREATE.createPayrollUpload);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewPayrollUpload)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewPayrollUpload,
                  PERMS.payrollUpload
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewPayrollUpload)}
              checked={role || permissions.includes(UPDATE.updatePayrollUpload)}
              onChange={e => {
                handleChange(e, UPDATE.updatePayrollUpload);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewPayrollUpload)}
              checked={role || permissions.includes(DELETE.deletePayrollUpload)}
              onChange={e => {
                handleChange(e, DELETE.deletePayrollUpload);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Market Data Upload</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewMarketDataUpload)}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewMarketDataUpload,
                  PERMS.marketDataUpload
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewMarketDataUpload)
              }
              checked={
                role || permissions.includes(CREATE.createMarketDataUpload)
              }
              onChange={e => {
                handleChange(e, CREATE.createMarketDataUpload);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewMarketDataUpload)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewMarketDataUpload,
                  PERMS.marketDataUpload
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewMarketDataUpload)
              }
              checked={
                role || permissions.includes(UPDATE.updateMarketDataUpload)
              }
              onChange={e => {
                handleChange(e, UPDATE.updateMarketDataUpload);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={
                role || !permissions.includes(VIEW.viewMarketDataUpload)
              }
              checked={
                role || permissions.includes(DELETE.deleteMarketDataUpload)
              }
              onChange={e => {
                handleChange(e, DELETE.deleteMarketDataUpload);
              }}
            />
          </td>
        </tr>
        <tr>
          <td className="per_table_td">
            <div>Offer Creation</div>
            <div className="helping_text">
              Some text to describe the above function
            </div>
          </td>
          <td className="per_table_td">
            <Switch
              disabled={role}
              checked={role || permissions.includes(VIEW.viewOfferCreation)}
              onChange={e => {
                handleSwitchChange(
                  e,
                  VIEW.viewOfferCreation,
                  PERMS.offerCreation
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewOfferCreation)}
              checked={role || permissions.includes(CREATE.createOfferCreation)}
              onChange={e => {
                handleChange(e, CREATE.createOfferCreation);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role}
              checked={role || permissions.includes(VIEW.viewOfferCreation)}
              onChange={e => {
                handleSwitchChange(
                  e.target.checked,
                  VIEW.viewOfferCreation,
                  PERMS.offerCreation
                );
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewOfferCreation)}
              checked={role || permissions.includes(UPDATE.updateOfferCreation)}
              onChange={e => {
                handleChange(e, UPDATE.updateOfferCreation);
              }}
            />
          </td>
          <td className="per_table_td">
            <Checkbox
              disabled={role || !permissions.includes(VIEW.viewOfferCreation)}
              checked={role || permissions.includes(DELETE.deleteOfferCreation)}
              onChange={e => {
                handleChange(e, DELETE.deleteOfferCreation);
              }}
            />
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default PermissionsTable;
